<template>
  <div class="channal-manage">
    <div class="header">
      <el-button
        v-if="getAddFlag()"
        class="add-btn"
        icon="el-icon-plus"
        @click.native="addHandle"
        type="primary"
        size="small"
      >新增</el-button>
    </div>
    <!--表格渲染-->
    <el-table
      border
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span>{{getChannalTypeText(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="支付方式名称"/>
      <el-table-column prop="limitation" label="金额限制">
        <template slot-scope="scope">
          <span>{{getLimit(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == 0 ? '启用' : '禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <el-button size="small" type="success" v-if="checkPermission(['ADMIN','TPayType_ALL','TPayType_EDIT'
])" @click="editHandle(scope.row)">编辑</el-button>
          <el-button size="small" type="danger"  v-if="checkPermission(['ADMIN','TPayType_ALL','TPayType_EDIT'
]) && scope.row.status == 0" @click="enAbledOrDisabledHandle(scope.row,2)">禁用</el-button>
          <el-button size="small" type="danger"  v-if="checkPermission(['ADMIN','TPayType_ALL','TPayType_EDIT']) && scope.row.status == 1 " @click="enAbledOrDisabledHandle(scope.row,1)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-dialog
      :dialog-show="dialogShow"
      @close-dialog="dialogShow = false"
      :pay-info="currentPayInfo"
      :is-add="isAdd"
    ></add-or-edit-dialog>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import addOrEditDialog from '@/components/bussetup/paymentManage/addOrEditDialog'
  import { addPayFee,disableOrStartUsePaymentManage } from '@/api/bussetup/paymentManage'
  export default {
    name:'paymentManage',
    components: { addOrEditDialog },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        dialogShow: false,
        isAdd: false,

        currentPayInfo:{},
      }
    },
    created() {
      this.$nextTick(() => {
        this.init();
      })
    },
    methods: {
      parseTime,
      checkPermission,
      getChannalTypeText(row){
        switch (row.oprType) {
          case 1:
            return '入金';
          case 2:
            return '出金';
          case 3:
            return '出佣'
        }
      },
      getLimit(row){
        let minStr = '', maxStr = '';
        if(row.minimumLimit){
          minStr = row.minimumLimit
        }else {
          minStr = '不限制'
        }
        if(row.maximumLimit){
          maxStr = row.maximumLimit
        }else {
          maxStr = '∞'
        }
        if(!row.minimumLimit && !row.maximumLimit){
          return '不限制';
        }
        return minStr + '-' + maxStr;
      },
      beforeInit() {
        this.url = 'crm/tPayType'
        this.params = { page: this.page, size: this.size }
        return true;
      },

      addHandle(){
        this.dialogShow = true;
        this.isAdd = true;
      },
      editHandle(row){
        this.isAdd = false;
        this.currentPayInfo = row;
        this.dialogShow = true;
      },
      enAbledOrDisabledHandle(row,status){
        disableOrStartUsePaymentManage({
          id: row.id
        }).then((res) => {
          this.$notify({
            title: '操作成功',
            type: 'success',
            duration: 2500
          });
          this.init();
        })
      },
      getAddFlag(){
        return checkPermission(['ADMIN','TPayType_ALL','TPayType_CREATE']);
      }
    }
  }
</script>

<style lang="less" scoped>
.channal-manage {
  padding: 30px;
  .header {
    margin-bottom: 20px;
  }
  .add-btn {
  }
}
</style>
